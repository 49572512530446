// add custom js below
import * as $ from 'jquery';

console.log(
  '%c Hello from /scripts/script.js ',
  'background: lemonchiffon; border: 1px solid #fff'
);
console.log(
  '%c Welcome to tris-webpack-boilerplate! ',
  'background: lemonchiffon; border: 1px solid #fff'
);
console.log(
  '%c Coded by: https://tris.codes 💠',
  'background: lavenderblush; border: 1px solid #000; padding: 4px; padding-top: 10px; padding-bottom: 8px;'
);

function toggleHeader(status = 'normal') {
  if (status === 'fixed') {
    $('#navbar_top').addClass('fixed-top');
    $('#navbar_top').addClass('dark-nav');
    // add padding top to show content behind navbar
    $('body').css('padding-top', `${$('.navbar').outerHeight()}px`);
  } else if (status === 'force-fixed-top') {
    $('#navbar_top').addClass('force-fixed-top');
    // add padding top to show content behind navbar
    // $('body').css('padding-top', `${$('.navbar').outerHeight()}px`);
  } else if (status === 'remove-force-fixed-top') {
    $('#navbar_top').removeClass('force-fixed-top');
    // add padding top to show content behind navbar
    // $('body').css('padding-top', '0');
  } else {
    $('#navbar_top').removeClass('fixed-top');
    $('#navbar_top').removeClass('dark-nav');

    // remove padding top from body
    $('body').css('padding-top', '0');
  }
}

$(document).ready(function () {
  console.log('ready!');
  if ($('.slider').length) {
    $('.slider').slick({
      dots: true,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      prevArrow: $('.slider-container .prev-arrow'),
      nextArrow: $('.slider-container .next-arrow'),
      autoplay: false
    });
  }
  
  if ($('.section-projects .boxes').length) {

    $('.section-projects .boxes').slick({
      dots: false,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      prevArrow: $('.section-projects .prev-arrow'),
      nextArrow: $('.section-projects .next-arrow'),
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
  if ($('.section-news .boxes').length) {

    $('.section-news .boxes').slick({
      dots: false,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      prevArrow: $('.section-news .prev-arrow'),
      nextArrow: $('.section-news .next-arrow'),
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
  /*
                                                                                  Sticky navbar
                                                                                  */
  if ($(window).width() > 992) {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 40) {
        toggleHeader('fixed');
      } else {
        toggleHeader();
      }
    });
  } else {
    $('#navbar_top').addClass('fixed-top');
    $('#navbar_top').addClass('dark-nav');
    // $('.responsive-header .header-bg').attr('style', 'display:none;');
    // $('.responsive-header').attr(
    //   'style',
    //   'height:auto !important; max-height:0 !important; margin-top:60px;'
    // );
    // $('.responsive-header .header-bg').attr('style', 'display:none !important;');
  }

  // playe js
  if ($('#my-player').length) {
    const player = videojs('my-player');
  }

  if ($('.page-careers #search-button').length) {
    $('.page-careers #search-button').click((e) => {
      window.location.href = 'search-results.html';
    });
  }

  if ($('.vacancy-details .form-toggle').length) {
    $('.vacancy-details .form-toggle').click((e) => {
      if ($('.vacancy-details .form-apply').length) {
        $('.vacancy-details .form-apply').removeClass('d-none');
        $('.vacancy-details .form-toggle').addClass('d-none');
      }
    });
  }

  if ($('.timeline-nav .nav-link').length) {
    for (const nav of $('.timeline-nav .nav-link')) {
      nav.addEventListener('shown.bs.tab', function (event) {
        if ($('.slider').length) {
          for (const nav of $('.slider')) {
            // console.log($('.slider'), 'reinit');
            $('.slider').slick('reinit');
          }
        }
        if ($('.amenities .slider-for-container .slider-for').length) {
          $('.amenities .slider-for-container .slider-for').slick('reinit');
        }
        if ($('.amenities .slider-nav-container .slider-nav').length) {
          $('.amenities .slider-nav-container .slider-nav').slick('reinit');
        }
        if ($('.gallery .slider-for-container .slider-for').length) {
          $('.gallery .slider-for-container .slider-for').slick('reinit');
        }
        if ($('.gallery .slider-nav-container .slider-nav').length) {
          $('.gallery .slider-nav-container .slider-nav').slick('reinit');
        } if ($('.construction-panel .slider-for-container .slider-for').length) {
          $('.construction-panel .slider-for-container .slider-for').slick('reinit');
        }
        if ($('.construction-panel .slider-nav-container .slider-nav').length) {
          $('.construction-panel .slider-nav-container .slider-nav').slick('reinit');
        }
        toggleHeader(nav.getAttribute('data-header'));
      });
    }
  }

  if ($('.timeline-nav .nav-link').length) {
    for (const nav of $('.timeline-nav .nav-link')) {
      nav.addEventListener('shown.bs.tab', function (event) {
        // console.log(nav.getAttribute('data-header'));
        toggleHeader(nav.getAttribute('data-header'));
      });
    }
  }

  if ($('.overview-description .description-toggler').length) {
    $('.overview-description .description-toggler').click((e) => {
      $('.overview-description').toggleClass('active');
    });
  }

  if ($('.amenities .slider-for-container .slider-for').length) {
    $('.amenities .slider-for-container .slider-for').slick({
      dots: true,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      autoplay: false,
      arrows: false,
      asNavFor: '.amenities .slider-nav-container .slider-nav'
    });
  }

  if ($('.amenities .slider-nav-container .slider-nav').length) {
    $('.amenities .slider-nav-container .slider-nav').slick({
      dots: false,
      infinite: false,
      speed: 300,
      vertical: true,
      // appendArrows: $('.slider_arrows'),
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      draggable: false,
      arrows: true,
      prevArrow: $('.amenities .slider-nav-container .prev-arrow'),
      nextArrow: $('.amenities .slider-nav-container .next-arrow'),
      // centerMode: true,
      focusOnSelect: true,

      asNavFor: '.amenities .slider-for-container .slider-for'
    });
  }

  if ($('.gallery .slider-for-container .slider-for').length) {
    $('.gallery .slider-for-container .slider-for').slick({
      dots: true,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      autoplay: false,
      arrows: false,
      asNavFor: '.gallery .slider-nav-container .slider-nav'
    });
  }

  if ($('.gallery .slider-nav-container .slider-nav').length) {
    $('.gallery .slider-nav-container .slider-nav').slick({
      dots: false,
      infinite: false,
      speed: 300,
      vertical: true,
      // appendArrows: $('.slider_arrows'),
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      draggable: false,
      arrows: true,
      prevArrow: $('.gallery .slider-nav-container .prev-arrow'),
      nextArrow: $('.gallery .slider-nav-container .next-arrow'),
      // centerMode: true,
      focusOnSelect: true,

      asNavFor: '.gallery .slider-for-container .slider-for'
    });
  }

  if ($('.construction-panel .slider-for-container .slider-for').length) {
    $('.construction-panel .slider-for-container .slider-for').slick({
      dots: true,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      autoplay: false,
      arrows: false,
      asNavFor: '.construction-panel .slider-nav-container .slider-nav'
    });
  }

  if ($('.construction-panel .slider-nav-container .slider-nav').length) {
    $('.construction-panel .slider-nav-container .slider-nav').slick({
      dots: false,
      infinite: false,
      speed: 300,
      vertical: true,
      // appendArrows: $('.slider_arrows'),
      autoplay: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      draggable: false,
      arrows: true,
      prevArrow: $('.construction-panel .slider-nav-container .prev-arrow'),
      nextArrow: $('.construction-panel .slider-nav-container .next-arrow'),
      // centerMode: true,
      focusOnSelect: true,

      asNavFor: '.construction-panel .slider-for-container .slider-for'
    });
  }

  if ($('.vacancy-details #uploadFileTrigger').length) {
    $('.vacancy-details #uploadFileTrigger').click((e) => {
      $('.vacancy-details #uploadFile').click();
    });
  }

  $( "#newsLink" ).on( "click", function() {
    $(".cd-menu .cd-timeline-img").removeClass('active');
    $(".cd-menu .cd-timeline-img.news-icon").addClass('active');
    $(".default-panel").addClass('d-none');
    $(".news-panel").removeClass('d-none');

  });
  $( "#tvLink" ).on( "click", function() {
    $(".cd-menu .cd-timeline-img").removeClass('active');
    $(".cd-menu .cd-timeline-img.tv-icon").addClass('active');
    $(".default-panel").addClass('d-none');
    $(".tv-panel").removeClass('d-none');
  });
  $( "#galleryLink" ).on( "click", function() {
    $(".cd-menu .cd-timeline-img").removeClass('active');
    $(".cd-menu .cd-timeline-img.photo-gallery-icon").addClass('active');
    $(".default-panel").addClass('d-none');
    $(".gallery-panel").removeClass('d-none');
  });
  $( "#newsNavSm" ).on( "click", function() {
    $(".cd-menu-link").removeClass('active');
    $(this).addClass('active');
    $(".default-panel").addClass('d-none');
    $(".news-panel").removeClass('d-none');
  });
  $( "#galleryNavSm" ).on( "click", function() {
    $(".cd-menu-link").removeClass('active');
    $(this).addClass('active');
    $(".default-panel").addClass('d-none');
    $(".gallery-panel").removeClass('d-none');
  });
  $( "#tvNavSm" ).on( "click", function() {
    $(".cd-menu-link").removeClass('active');
    $(this).addClass('active');
    $(".default-panel").addClass('d-none');
    $(".tv-panel").removeClass('d-none');
  });

  //loader
  $(window).ready(function () {
    $('.overlay, body').addClass('loaded');
    // $('.overlay').css({ display: 'block' });

    setTimeout(function () {
      $('.overlay').css({ display: 'none' });
      if ($('.overview-description .description-toggler').length) {
        setTimeout(function () {
          $('.overview-description').toggleClass('active');
    
        }, 200);
        setTimeout(function () {
          $('.overview-description').toggleClass('active');
    
        }, 5000);
      }
    }, 2000);
 });
  // $(window).on('load', function () {
  //   console.log("loaded");
  //   $('.overlay, body').addClass('loaded');
  //   setTimeout(function () {
  //     $('.overlay').css({ display: 'none' });
  //   }, 2000);
  // });

  if ($('.available-floors .slider-grid').length) {
    $('.available-floors .slider-grid').slick({
      dots: false,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      autoplay: false,
      draggable: false,
      arrows: true,
      prevArrow: $('.available-floors .prev-arrow'),
      nextArrow: $('.available-floors .next-arrow'),
      rows: 2,
      slidesPerRow: 1,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1
          }
        }
      ]
    });
  }

  const myModalEl = document.getElementById('available-floors');
  if (myModalEl) {
    myModalEl.addEventListener('shown.bs.modal', function (event) {
      $('.available-floors .slider-grid').slick('reinit');
    });
  }

  if ($('.projects-links .slider-links').length) {
    $('.projects-links .slider-links').slick({
      dots: false,
      infinite: false,
      speed: 300,
      // appendArrows: $('.slider_arrows'),
      autoplay: false,
      draggable: false,
      arrows: true,
      prevArrow: $('.projects-links .prev-arrow'),
      nextArrow: $('.projects-links .next-arrow'),
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }

  if ($('.unit-details-for').length) {
    $('.unit-details-for').slick({
      // dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      // arrows:true,
      prevArrow: $('.units-slider-arrows .prev-arrow'),
      nextArrow: $('.units-slider-arrows .next-arrow'),
      asNavFor: '.unit-details-nav'
    });
  }

  if ($('.unit-details-nav').length) {
    $('.unit-details-nav').slick({
      infinite: false,
      speed: 300,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      draggable: false,
      // dots: true,
      arrows:false,
      // prevArrow: $('.units-slider-arrows .prev-arrow'),
      // nextArrow: $('.units-slider-arrows .next-arrow'),
      // centerMode: true,
      focusOnSelect: true,

      asNavFor: '.unit-details-for'
    });
  }
});
